<template>
	<div id="account_manage">
		<jy-query ref="form" :model="form">
			<jy-query-item prop="userNa" label="登录账号:">
				<el-input placeholder="请输入" v-model="form.userNa"></el-input>
			</jy-query-item>
			<jy-query-item prop="roleId" label="所属角色:">
				<el-select v-model="form.roleId" placeholder="请选择">
					<el-option label="全部" value=""></el-option>
					<el-option v-for="item in statusList" :key="item.roleId" :label="item.roleNa" :value="item.roleId"></el-option>
				</el-select>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck" v-if="btnexist('scheduleSystemSettingsAccountManagementList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')" v-if="btnexist('scheduleSystemSettingsAccountManagementReset')"
					>重置</el-button
				>
				<!-- <el-row>
				</el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" v-on:click="addUdate()" v-if="btnexist('scheduleSystemSettingsAccountManagementInsert')"
					>新增</el-button
				>
				<!-- <el-button type="primary">批量删除</el-button> -->
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<!-- <jy-table-column type="selection" width="40"></jy-table-column> -->
			<jy-table-column prop="userNa" label="登录账号" min-width="120"></jy-table-column>
			<jy-table-column prop="markNa" label="用户名称" min-width="120"></jy-table-column>
			<jy-table-column prop="orgNa" label="所属组织" min-width="200"></jy-table-column>
			<jy-table-column prop="roleNa" label="所属角色" min-width="160"></jy-table-column>
			<jy-table-column label="企业logo" min-width="100">
				<template slot-scope="scope">
					<el-image :src="scope.row.image" class="logo_img" fit="cover" :preview-src-list="[scope.row.image]">
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
						</div>
					</el-image>
				</template>
			</jy-table-column>
			<jy-table-column label="账号状态" min-width="80">
				<template slot-scope="scope">{{ scope.row.active | state }}</template>
			</jy-table-column>
			<jy-table-column prop="createT" label="创建日期" min-width="160"></jy-table-column>
			<jy-operate v-bind:list="operateList" width="200"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<add-update-account ref="addUpdateAccount" @updateList="getList"></add-update-account>
		<edit-password ref="editPassword" @updateList="getList"></edit-password>
	</div>
</template>
<script>
import addUpdateAccount from "@/components/pages/admin/systemSettings/accountManagement/addUpdateAccount";
import editPassword from "@/components/pages/admin/systemSettings/accountManagement/editPassword";
import { mapGetters } from "vuex";
import { btnMixins } from "@/common/js/button.mixin";

export default {
	data() {
		return {
			statusList: [],
			form: {
				userNa: "",
				roleId: ""
			},
			pageSize: 10,
			total: 10,
			pageIndex: 1,
			dataList: [],
			operateList: [],

			btnMenuId: "scheduleSystemSettingsAccountManagement"
		};
	},
	mixins: [btnMixins],
	components: {
		addUpdateAccount,
		editPassword
	},
	activated() {
		this.getAllList();
	},
	created() {
		this.getList();
		this.setOperateList();
	},
	filters: {
		state(val) {
			if (val == 1) {
				return "启用";
			} else {
				return "禁用";
			}
		}
	},
	computed: {
		...mapGetters(["userInfoGet"])
	},
	methods: {
		setOperateList() {
			let l = [
				{
					name: "重置密码",
					icon: "el-icon-edit-outline",
					fun: this.resetPassword,
					isShow: () => {
						return this.btnexist("scheduleSystemSettingsAccountManagementResetPwd");
					}
				},

				{
					name: "修改",
					icon: "el-icon-edit-outline",
					fun: this.addUdate,
					isShow: () => {
						return this.btnexist("scheduleSystemSettingsAccountManagementUpdate");
					}
				},
				{
					name: "修改密码",
					icon: "el-icon-edit-outline",
					fun: this.modifyPassword
					// isShow: () => {
					// 	return this.btnexist("scheduleSystemSettingsAccountManagementUpdate");
					// }
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete,
					isShow: () => {
						return this.btnexist("scheduleSystemSettingsAccountManagementDelete");
					}
				}
			];
			this.operateList = l;
		},
		getAllList() {
			let option = {
				pageIndex: 1,
				pageSize: 10000,
				sysRole: {}
			};
			let url = "/sys/role/pageQueryRole";
			this.$http.post(url, option).then(({ detail }) => {
				if (detail) {
					// this.statusList = [{ roleNa: "全部", roleId: "" }];
					this.statusList = this.statusList.concat(detail.list.map(v => v.sysRole));
				}
			});
		},
		getList() {
			let option = {
				sysUser: this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/sys/user/pageQuerySysUser";
			console.log(option);
			this.$http.post(url, option, { isRequestParam: false }).then(({ detail }) => {
				this.dataList = detail.list.map(v => {
					return {
						...v.sysOrg,
						...v.sysRole,
						...v.sysUser,
						createT: this.$util.formatTime(v.sysUser.createT)
					};
				});
				this.total = detail.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该账号?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = row.userId;
					let url = "/sys/user/delete";
					this.$http.post(url, option, { isRequestParam: false }).then(res => {
						// console.log(res)
						this.$message({
							type: "success",
							message: res.msg
						});
						this.getList();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		addUdate(option) {
			if (option) {
				this.$refs.addUpdateAccount.init(option, "update");
			} else {
				this.$refs.addUpdateAccount.init();
			}
		},
		//修改密码
		modifyPassword(option) {
			if (option) {
				this.$refs.editPassword.init(option.userId, "update");
			} else {
				this.$refs.editPassword.init();
			}
		},

		resetPassword(row) {
			let url = "/sys/user/resetPassword";
			let option = row.userId;
			this.$http.post(url, option, { isRequestParam: false }).then(res => {
				this.$message({
					type: "success",
					message: res.msg
				});
				this.getList();
			});
		}
	}
};
</script>
<style scoped="accountManagement" lang="scss">
#account_manage {
	.logo_img {
		width: 60px;
		height: 60px;

		i {
			font-size: 60px;
		}
	}
}
</style>
