<template>
	<div class="edit-password">
		<jy-dialog type="form" title="修改密码" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
			<div>
				<el-form :model="diaform" ref="dialogForm" :rules="rules" :label-width="formLabelWidth">
					<el-form-item label="新密码:" prop="passwd">
						<el-input placeholder="请输入新密码" v-model="diaform.passwd" show-password></el-input>
					</el-form-item>
					<el-form-item label="确认新密码:" prop="cPasswd">
						<el-input placeholder="请再次输入新密码" v-model="diaform.cPasswd" show-password></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</jy-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			isShow: true,
			diaform: {
				userId: "", //用户id
				passwd: "",
				cPasswd: "" //再次输入新密码
			},
			rules: {
				passwd: [
					{
						required: true,
						message: "请输入新密码",
						trigger: "blur"
					}
				],
				cPasswd: [
					{
						required: true,
						message: "请输入确认新密码",
						trigger: "blur"
					}
				]
			},
			dialogVisible: false,
			formLabelWidth: "100px"
		};
	},
	created() {},
	components: {},
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm("dialogForm");
			}
		}
	},
	methods: {
		init(userId) {
			this.dialogVisible = true;
			if (userId) {
				this.$nextTick(() => {
					this.diaform.userId = userId;
				});
			}
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		//取消修改密码
		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		save() {
			this.$refs.dialogForm.validate(valid => {
				if (valid) {
					let url = "/sys/user/modifyPassword";
					let userId = this.diaform.userId;
					// 获取两次输入的密码
					let password1 = this.diaform.passwd;
					var password2 = this.diaform.cPasswd;
					// 判断密码是否相同
					if (password1 !== password2) {
						this.$message({
							type: "error",
							message: "两次输入的密码不一致，请重新输入"
						});
						return false; // 阻止表单提交
					} else {
						let passwd = this.diaform.passwd;
						this.$http.post(url, { userId, passwd }).then(res => {
							this.$message({
								type: "success",
								message: res.msg
							});
							this.dialogVisible = false;
						});
					}
				} else {
					return false;
				}
			});
		}
	}
};
</script>
